import { flattenColors } from '@/core/plugins/vuetify/colors/color.helper'
import type { TrTheme } from '@/core/plugins/vuetify/theme/theme.interface'
import { ClicSanteLegacyColors } from '@/core/config/themes/legacyColors'
import { ClicSanteColors } from '@/core/config/themes/colors'
import { ClicSanteThemeComponentColors } from '@/core/config/themes/dark/theme.componentColors'
import { ClicSanteThemeVariables } from '@/core/config/themes/dark/theme.variables'

export const Theme: TrTheme = {
  id: 'dark',
  dark: false,
  colors: {
    ...ClicSanteLegacyColors,
    ...flattenColors(ClicSanteColors),
    ...ClicSanteThemeComponentColors
  },
  variables: {
    ...ClicSanteThemeVariables
  }
}

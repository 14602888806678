import type { TrColor } from '@/core/plugins/vuetify/colors/color.interface'

const grey: TrColor = {
  '25': '#FCFCFD',
  '50': '#f9fafb',
  '100': '#f2f4f7',
  '200': '#e4e7ec',
  '300': '#d0d5Dd',
  '400': '#98a2b3',
  '500': '#667085',
  '600': '#475467',
  '700': '#344054',
  '800': '#1d2939',
  '900': '#101828'
}

const blue: TrColor = {
  '25': '#f3f7fc',
  '50': '#e3f3ff',
  '100': '#c5daf4',
  '200': '#98bdeb',
  '300': '#6ba0e3',
  '400': '#3e83da',
  '500': '#1167d2',
  '600': '#0d52a8',
  '700': '#0a3d7e',
  '800': '#072d5c',
  '900': '#031429'
}

const red: TrColor = {
  '25': '#fffbfa',
  '50': '#fef3f2',
  '100': '#fee4e2',
  '200': '#f9c8c7',
  '300': '#f59c9a',
  '400': '#f97066',
  '500': '#f04438',
  '600': '#d92d20',
  '700': '#b42318',
  '800': '#912018',
  '900': '#7a271a'
}

const orange: TrColor = {
  '25': '#fffaf5',
  '50': '#fff6ed',
  '100': '#ffead5',
  '200': '#fddcab',
  '300': '#feb273',
  '400': '#fd853a',
  '500': '#fb6514',
  '600': '#ec4a0a',
  '700': '#c4320a',
  '800': '#9c2a10',
  '900': '#7e2410'
}

const green: TrColor = {
  '25': '#f6fef9',
  '50': '#ecfdf3',
  '100': '#d1fadf',
  '200': '#a6f4c5',
  '300': '#6ce9a6',
  '400': '#32d583',
  '500': '#12b76a',
  '600': '#039855',
  '700': '#027a48',
  '800': '#05603a',
  '900': '#054f31'
}

const others = {
  primary2: '#fcc987',
  black: '#1e1e1e',
  white: '#ffffff'
}

export const ClicSanteColors = {
  grey,
  blue,
  red,
  orange,
  green,
  ...others
}

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { Theme as LightTheme } from '@/core/config/themes/light/theme'
import { Theme as DarkTheme } from '@/core/config/themes/dark/theme'
import type { TrTheme } from '@/core/plugins/vuetify/theme/theme.interface'
import { mergeCoreAndProjectThemes } from '@/core/plugins/vuetify/theme/theme.helper'

export const vuetifyThemeOpts = {
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    }
  },
  theme: {
    themes: {}
  }
}

export const createVuetifyWithOptions = (themes: TrTheme[] = {}) => {
  vuetifyThemeOpts.theme.themes[LightTheme.id] = LightTheme
  vuetifyThemeOpts.theme.themes[DarkTheme.id] = DarkTheme
  vuetifyThemeOpts.theme.defaultTheme = LightTheme.id

  mergeCoreAndProjectThemes(themes, vuetifyThemeOpts.theme.themes)
  return createVuetify({ ...vuetifyThemeOpts })
}

export default {
  install(app, options = {}) {
    const vuetifyInstance = createVuetifyWithOptions(options)
    app.use(vuetifyInstance)
    app.provide('$vuetify', vuetifyInstance)
  }
}

import type { TrTheme } from '@/core/plugins/vuetify/theme/theme.interface'

export function setFavicon(product) {
  /* show the right favicon for product */
  const searchString = 'favicon-' + product
  const x = document.querySelectorAll('link')
  for (let i = 0; i < x.length; i++) {
    const str = x[i].href
    const str2 = x[i].rel
    if (str.search(searchString) === -1 && str2 !== 'stylesheet') {
      x[i].remove()
    }
  }
}

export function setProduct(domain) {
  let product = 'clicsante'
  if (
    import.meta.env.VITE_APP_ENV === 'development' &&
    import.meta.env.VITE_APP_PRODUCT !== 'auto-detect'
  ) {
    product = import.meta.env.VITE_APP_PRODUCT
  } else if (domain.search('pharmaprix') !== -1) {
    product = 'pharmaprix'
  } else if (domain.search('uniprix') !== -1) {
    product = 'uniprix'
  } else if (domain.search('pharmaservices') !== -1) {
    product = 'pharmaservices'
  }
  if (product !== 'clicsante') {
    window.location = 'https://portal3.clicsante.ca'
  }

  return product
}

export function mergeCoreAndProjectThemes(projectThemes: TrTheme[], coreThemes) {
  projectThemes?.forEach((projectTheme: TrTheme) => {
    const coreTheme = coreThemes[projectTheme.id]
    if (!coreTheme) {
      coreThemes[projectTheme.id] = {}
    }
    updateCoreThemeKey('colors', projectTheme, coreTheme)
    updateCoreThemeKey('variables', projectTheme, coreTheme)
  })
}

const updateCoreThemeKey = (key: string, projectTheme, coreTheme = {}) => {
  if (projectTheme[key]) {
    coreTheme[key] = {
      ...coreTheme[key],
      ...projectTheme[key]
    }
  }
}

export const flattenColors = (colors) => {
  const result = {}
  for (const [key, value] of Object.entries(colors)) {
    if (typeof value === 'object') {
      for (const [subKey, subValue] of Object.entries(value)) {
        result[`${key}-${subKey}`] = subValue
      }
    } else {
      result[key] = value
    }
  }
  return result
}

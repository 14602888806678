import { setProduct } from '@/core/plugins/vuetify/theme/theme.helper'

const colors = {
  pharmaservices: {
    primary: '#00A84E',
    secondary: '#4ac380',
    accent: '#badfbf',
    error: '#cc0a00',
    info: '#badfbf',
    success: '#006830',
    warning: '#ff8500'
  },
  uniprix: {
    primary: '#0c4da2',
    secondary: '#ff8500',
    accent: '#7396c4',
    error: '#cc0a00',
    info: '#eaf4ff',
    success: '#4ea835',
    warning: '#ff8500'
  },
  pharmaprix: {
    primary: '#cf342f',
    secondary: '#45add6',
    accent: '#cf6666',
    error: '#cc0a00',
    info: '#45add6',
    success: '#cc0a00',
    warning: '#ff8500'
  },
  clicsante: {
    primary: '#003E6C',
    secondary: '#00D2E6',
    accent: '#6fa7fc',
    error: '#cc0a00',
    info: '#eaf4ff',
    success: '#4caf50',
    warning: '#ff8500'
  }
}

export const ClicSanteLegacyColors = colors[setProduct(window.location.hostname) || 'clicsante']
